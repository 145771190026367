import CenteredContent from '../../components/CenteredContent';
import Typography from '@material-ui/core/Typography';

export default function Billing() {
  return (
    <CenteredContent>
      <Typography variant="h4">Billing</Typography>
      <Typography variant="body2">Setup your billing plan</Typography>
    </CenteredContent>
  );
}
