import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firestore, auth } from '../firebase';

export const createSite = createAsyncThunk(
  'sites/add',
  async (site) => {
    const res = await firestore.collection('Sites').add({ ownerId: auth.currentUser.uid, ...site });

    return { id: res.id, ownerId: auth.currentUser.uid, ...site };
  },
);

export const getAllSites = createAsyncThunk(
  'sites/getAll',
  async ({ projectId }) => {
    const res = await firestore.collection('Sites')
      .where('projectId', '==', projectId)
      .where('ownerId', '==', auth.currentUser.uid)
      .get();

    return res.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },
);

export default createSlice({
  name: 'stores',
  initialState: {
    all: [],
  },
  extraReducers: {
    [createSite.fulfilled]: (state, action) => {
      state.all.push(action.payload);
    },
    [createSite.rejected]: (state, action) => {
      console.error('create site failed', action);
    },
    [getAllSites.fulfilled]: (state, action) => {
      state.cachedAt = Date.now();
      state.all = action.payload;
    },
    [getAllSites.rejected]: (state, action) => {
      console.error('get all sites failed', action);
    }
  },
})
