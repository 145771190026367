import 'firebaseui/dist/firebaseui.css';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {auth} from '../firebase';
import userSlice, {login} from '../slices/user';
import Typography from '@material-ui/core/Typography';

function useCurrentUser() {
  return useSelector(state => state.user.currentUser);
}

export default function Auth({ children }) {
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const isLoggingIn = useSelector(state => state.user.isLoggingIn);
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(userSlice.actions.authStateChanged(user.toJSON()));
      }

      setIsCheckingUser(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!currentUser && !isCheckingUser && !isLoggingIn) {
      dispatch(login());
    }
  }, [isCheckingUser, currentUser, isLoggingIn, dispatch]);

  if (currentUser) {
    return children;
  }

  if (!isLoggingIn) { return null; }

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Typography variant="h3">Welcome to Extellerate</Typography>
      <Typography variant="body2">
        Create your account to get started
      </Typography>
      <div id="firebase-auth-container" style={{ marginTop: 32 }} />
    </div>
  )
}

