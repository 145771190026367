import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import SiteForm from '../../components/SiteForm';
import { createSite } from '../../slices/sites';
import useCurrentProject from '../../hooks/useCurrentProject';
import CenteredContent from '../../components/CenteredContent';

export default function CreateSite() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: projectId } = useCurrentProject();
  const form = useForm({
    name: 'site',
    defaultValues: {
      name: '',
      domains: '',
    },
  });

  async function onSubmit(values) {
    const domains = values.domains.split(/[,\s]+/);
    const res = await dispatch(createSite({
      ...values,
      projectId,
      domains,
    }));

    history.push(`/integrations/create?siteId=${res.payload.id}&projectId=${projectId}`);
  }

  return (
    <div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CenteredContent style={{ minHeight: '100vh' }}>
          <div style={{ marginBottom: 32 }}>
            <Typography variant="h4">Create a new Site</Typography>
            <Typography variant="body">Add a site that your Extension will run on</Typography>
          </div>
          <SiteForm form={form} />
        </CenteredContent>
        <AppBar position="sticky" color="default" style={{ bottom: 0, width: '100%' }}>
          <Toolbar style={{ display: 'flex', justifyContent : 'flex-end' }}>
            <div style={{ marginRight: 8 }}>
              <Button type="button" variant="outlined" color="default" onClick={() => history.push(`/sites?projectId=${projectId}`)}>Cancel</Button>
            </div>
            <Button type="submit" variant="contained" color="primary">Create</Button>
          </Toolbar>
        </AppBar>
      </form>
    </div>
  )
}
