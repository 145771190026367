import { useState } from 'react';
import { functions } from '../../firebase';
import CodeEditor from '../../components/CodeEditor';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Link from '../../components/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useCurrentProject from '../../hooks/useCurrentProject';
import useIntegrations from '../../hooks/useIntegrations';

const generateTemplate = functions.httpsCallable('generateTemplate');

async function downloadTemplate(options) {
  const { data: { fileUrl } } = await generateTemplate(options);

  window.open(fileUrl, '__blank');
}

function ConfigurationPanel() {
  const project = useCurrentProject();
  const [setupOption, setSetupOption] = useState(0);
  const contentCode = `import Extellerate from 'extellerate';
  
const extellerateApp = Extellerate.start();`;


  const backgroundCode = `import Extellerate from 'extellerate';
  
Extellerate.connect({
  appId: '${project.id}',
});`;

  const snippets = (
    <div>
      <div>
        <h4>Content Script</h4>
        <p>Paste the following into your extension content script to setup your app.</p>
        <CodeEditor value={contentCode} readOnly />
      </div>
      <div>
        <h4>Background Script</h4>
        <p>Paste the following into your extension background script to setup your app.</p>
        <CodeEditor value={backgroundCode} readOnly />
      </div>
    </div>
  );

  return (
    <div style={{ marginBottom: 32 }}>
      <Typography variant="h4">Setup</Typography>
      <Typography variant="body2">How do you want to get started with Extellerate?</Typography>

      <div style={{ marginTop: 32 }}>
        <AppBar position="static" color="default">
          <Tabs
            value={setupOption}
            onChange={(event, option) => setSetupOption(option)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Create New Extension" />
            <Tab label="Add to Existing Extension" />
          </Tabs>
        </AppBar>
        <Paper square style={{ padding: 16 }}>
          { setupOption === 0 && (
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <Typography variant="body2">There's nothing like starting a project from scratch! Click the link below to generate a Chrome Extension, preconfigured for this project.</Typography>
              <div style={{ marginTop: 32 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => downloadTemplate({
                    projectId: project.id,
                    projectName: project.name,
                    sdkVersion: '0.0.5',
                  })}
                >
                  Download Extension Template
                </Button>
              </div>
            </div>
          ) }
          { setupOption === 1 && snippets }
        </Paper>
      </div>
    </div>
  );
}

export default function Settings() {
  const { id: projectId } = useCurrentProject();
  const integrations = useIntegrations({ projectId: projectId });

  return (
    <div style={{ padding: 32, display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: 700, width: '100%' }}>
        <ConfigurationPanel />

        {!integrations.all.length && (
          <div style={{ marginTop: 64, padding: 16 }}>
            <Typography variant="h5">Once that's all set...</Typography>
            <Typography variant="body2">
              <Link to={`/integrations/create/?projectId=${projectId}`}>Create an Integration</Link> to bring your extension to life!
              You can always return to the Setup page (menu on the left) to find this information later.
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
};
