import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {auth, firestore} from '../firebase';

export const saveScript = createAsyncThunk(
  'scripts/save',
  async ({ id, projectId, integrationId, source }) => {
    const collection = firestore.collection('Scripts');

    if (id) {
      await collection.doc(id).set({ source }, { merge: true });

      return { source, id };
    }

    const res = await collection.add({
      ownerId: auth.currentUser.uid,
      integrationId,
      projectId,
      source,
    });

    return { id: res.id, source };
  }
)

export default createSlice({
  name: 'scripts',
  initialState: {
    byId: {},
  },
  extraReducers: {
    [saveScript.rejected]: (state, action) => {
      console.error('failed to save script', action);
    },
    [saveScript.fulfilled]: (state, action) => {
      state.byId[action.payload.id] = action.payload;
    },
  }
})
