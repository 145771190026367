import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider as ReduxProvider } from 'react-redux';
import TopNav from './components/TopNav';
import AppDrawer from './components/AppDrawer';
import Auth from './components/Auth';
import store from './store';

import IntegrationsIndexPage from './pages/integrations';
import IntegrationsCreatePage from './pages/integrations/create';
import IntegrationEditPage from './pages/integrations/edit';
import ParserPage from './pages/parsers';
import AnchorPage from './pages/anchors';
import SitesIndexPage from './pages/sites';
import SitesCreatePage from './pages/sites/create';
import MonitoringPage from './pages/monitoring';
import BillingPage from './pages/billing';
import TestCaseBuilderPage from './pages/monitoring/test-cases/create';
import SettingsPage from './pages/settings';
import useCurrentProject from './hooks/useCurrentProject';
import ProjectForm from './components/ProjectForm';
import useProjects from './hooks/useProjects';

const theme = createMuiTheme({});

function Project() {
  const currentProject = useCurrentProject();
  const projects = useProjects();
  const projectsLoading = useSelector(state => state.projects.pending);

  if (projectsLoading) {
    return 'Loading Your Extensions...';
  }

  if (!currentProject) {
    return <ProjectForm close={() => null} isFirst={!!projects.length} />
  }

  return (
    <Switch>
      <Route path="/integrations/create" component={IntegrationsCreatePage} />
      <Route path="/integrations/:integrationId" component={IntegrationEditPage} />
      <Route exact path="/integrations" component={IntegrationsIndexPage} />
      <Route path="/parsers" component={ParserPage} />
      <Route path="/anchors" component={AnchorPage} />
      <Route exact path="/sites" component={SitesIndexPage} />
      <Route path="/sites/create" component={SitesCreatePage} />
      <Route path="/settings" component={SettingsPage} />
      <Route exact path="/monitoring" component={MonitoringPage} />
      <Route path="/monitoring/test-cases/create" component={TestCaseBuilderPage} />
      <Route path="/billing" component={BillingPage} />
      <Redirect to={`/integrations?projectId=${currentProject.id}`} />
    </Switch>
  );
}

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Auth>
          <Router>
            <div style={{ display: 'flex' }}>
              <CssBaseline />
              <TopNav onMenuClick={() => setDrawerOpen(true)} />
              <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
              <div style={{ position: 'relative', width: '100%' }}>
                <Toolbar />
                <Project />
              </div>
            </div>
          </Router>
        </Auth>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
