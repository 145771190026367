import {Fragment, useEffect, useMemo, useState} from 'react';
import CenteredContent from '../../components/CenteredContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import utc from 'dayjs/plugin/utc';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import useCurrentProject from '../../hooks/useCurrentProject';
import useIntegrations from '../../hooks/useIntegrations';
import { firestore } from '../../firebase';
import { Sparklines, SparklinesLine } from '@anubhavsahoo/react-sparklines';
import useSites from '../../hooks/useSites';

dayjs.extend(utc);

function getCurrentIntervalTimestamp(interval) {
  if (interval === 'Minute') {
    return dayjs().utc().startOf('minute');
  }

  if (interval === 'Hour') {
    return dayjs().utc().startOf('hour');
  }

  if (interval === 'Day') {
    return dayjs().utc().startOf('day');
  }

  if (interval === 'Month') {
    return dayjs().utc().startOf('month');
  }

  throw new Error(`Unsupported interval: ${interval}`);
}

function getStartTime(interval) {
  const date = getCurrentIntervalTimestamp(interval);

  switch (interval) {
    case 'Minute':
      return date.subtract(15, 'minutes');

    case 'Hour':
      return date.startOf('hour').subtract(24, 'hours');

    case 'Day':
      return date.startOf('day').subtract(30, 'days');

    case 'Month':
      return date.startOf('month').subtract(12, 'months');

    default:
      throw new Error(`Unsupported interval: ${interval}`);
  }
}

function getNextTick(interval) {
  const now = dayjs().utc();

  switch (interval) {
    case 'Minute':
      const nextMinute = dayjs().utc().startOf('minute').add(1, 'minute');

      return nextMinute.diff(now);

    case 'Hour':
      const nextHour = dayjs.utc().startOf('hour').add(1, 'hour');

      return nextHour.diff(now);

    case 'Day':
      const nextDay = dayjs.utc().startOf('day').add(1, 'day');

      return nextDay.diff(now);

    case 'Month':
      const nextMonth = dayjs.utc().startOf('month').add(1, 'month');

      return nextMonth.diff(now);

    default:
      throw new Error(`Unknown interval: ${interval}`);
  }
}

function incrementTimestamp(timestamp, interval) {
  switch (interval) {
    case 'Minute':
      return timestamp.add(1, 'minute');

    case 'Hour':
      return timestamp.add(1, 'hour');

    case 'Day':
      return timestamp.add(1, 'day');

    case 'Month':
      return timestamp.add(1, 'month');

    default:
      throw new Error(`Invalid interval: ${interval}`);
  }
}

function getTimeseries (counts, interval) {
  let timestamp = getStartTime(interval);
  const timeseries = [];

  while (timestamp.valueOf() <= dayjs().utc().valueOf()) {
    const time = timestamp.valueOf();
    const result = counts[time] || 0;
    timeseries.push(result);
    timestamp = incrementTimestamp(timestamp, interval);
  }

  return timeseries;
}

function useSparkData(collection, id, type, interval) {
  const metric = `${type}By${interval}`;
  const startTime = useMemo(() => getStartTime(interval), [interval]);
  const startTimeValue = startTime.valueOf();
  const [sparkData, setSparkData] = useState([]);

  useEffect(() => {
    let nextTick;

    function scheduleNextTick() {
      const nextTickInterval = getNextTick(interval)
      nextTick = setTimeout(() => {
        setSparkData([...sparkData.slice(1, sparkData.length), 0]);

        scheduleNextTick();
      }, nextTickInterval);
    }

    scheduleNextTick();

    return () => {
      clearTimeout(nextTick);
    };
  }, [interval, sparkData]);

  useEffect(() => {
    let unsubscribe;

    (async () => {
      unsubscribe = firestore.collection(collection).doc(id).collection(metric)
        .where('timestamp', '>=', new Date(startTimeValue))
        .onSnapshot(({ docs }) => {
          const countsByTimestamp = docs.reduce((acc, doc) => {
            const data = doc.data() ;
            const timestamp = data.timestamp.seconds * 1000;

            return {...acc, [timestamp]: data.count };
          }, {});

          setSparkData(getTimeseries(countsByTimestamp, interval));
        });
    })();

    return () => { if (unsubscribe) unsubscribe() };
  }, [metric, startTimeValue, interval, collection, id]);

  return sparkData;
}

function IntegrationRow({ integration, interval }) {
  const showsSparkData = useSparkData('Integrations', integration.id, 'Shows', interval);
  const errorsSparkData = useSparkData('Integrations', integration.id, 'Errors', interval);
  const sites = useSites();
  const site = sites.find(site => site.id === integration.siteId);
  const max = Math.max(...[...showsSparkData, ...errorsSparkData]) || 1;

  return (
    <TableRow key={integration.id}>
      <TableCell>{ site && site.name }</TableCell>
      <TableCell>
        <Sparklines data={showsSparkData} min={0} max={max}>
          <SparklinesLine color="blue"/>
        </Sparklines>
      </TableCell>
      <TableCell>
        <Sparklines data={errorsSparkData} min={0} max={max}>
          <SparklinesLine color="red"/>
        </Sparklines>
      </TableCell>
    </TableRow>
  )
}

function ErrorRow({ error, integrations }) {
  const history = useHistory();
  const integration = integrations.all.find(integration => integration.id === error.integrationId);
  const sites = useSites();
  const site = sites.find(site => integration && (site.id === integration.siteId));

  return (
    <TableRow>
      <TableCell style={{ maxWidth: 175, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'  }}>
        <div>
          <Typography variant="body">
            { integration ? integration.name : 'Unknown' }
            &nbsp;on&nbsp;
            { site ? site.name : 'Unknown' }
          </Typography>
        </div>
      </TableCell>
      <TableCell style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 250 }} title={ error.message }>
        { error.message }
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={ () => {
            if (error.taskType === 'Parser') {
              history.push(`/parsers?integrationId=${integration.id}&parserId=${error.taskId}&projectId=${integration.projectId}`, { debugError: error, })
            } else if (error.taskType === 'Anchor') {
              history.push(`/anchors?integrationId=${integration.id}&anchorId=${error.taskId}&projectId=${integration.projectId}`, { debugError: error, })
            } else {
              history.push(`/integations/${integration.id}?projectId=${integration.projectId}`, { debugError: error });
            }
          } }
        >
          Fix
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default function Monitoring() {
  const project = useCurrentProject();
  const integrations = useIntegrations({ projectId: project.id });
  const [interval, setInterval] = useState('Minute');
  const pageLoadsSparkData = useSparkData('Projects', project.id, 'PageLoads', interval);
  const integrationShowsSparkData = useSparkData('Projects', project.id, 'TotalIntegrationShows', interval);
  const integrationErrorsSparkData = useSparkData('Projects', project.id, 'TotalIntegrationErrors', interval);
  const totalPageLoads = pageLoadsSparkData.reduce((acc, num) => acc + num, 0);
  const totalIntegrationShows = integrationShowsSparkData.reduce((acc, num) => acc + num, 0);
  const totalIntegrationErrors = integrationErrorsSparkData.reduce((acc, num) => acc + num, 0);
  const averageIntegrationShowRate = Math.round(totalIntegrationShows / totalPageLoads * 100);
  const averageIntegrationErrorRate = Math.round(totalIntegrationErrors / totalPageLoads * 100);
  const [recentErrors, setRecentErrors] = useState([]);

  const integrationsById = integrations.all.reduce((acc, integration) => {
    const others = acc[integration.name] || [];
    return { ...acc, [integration.name]: [...others, integration] };
  }, {});

  useEffect(() => {
    const startTime = getStartTime(interval);
    const unsubscribe = firestore.collection('Projects').doc(project.id)
      .collection('ErrorsRaw')
      .where('clientTs', '>=', startTime.toDate())
      .orderBy('clientTs', 'desc')
      .limit(10)
      .onSnapshot(({ docs }) => {
        const errors = docs.map(doc => doc.data()).reduce((acc, error) => {
          let taskType = 'Integration';
          let taskId = error.integrationId;

          if (error.parserId){
            taskType = 'Parser';
            taskId = error.parserId;
          }

          if (error.anchorId) {
            taskType = 'Anchor';
            taskId = error.anchorId;
          }

          const key = `${error.message}:${error.step}:${taskType}:${taskId}`;
          const value = acc[key] || {
            integrationId: error.integrationId,
            message: error.message,
            taskId,
            taskType,
            step: error.step,
            occurrences: [],
          };

          value.occurrences.push(error);

          return { ...acc, [key]: value };
        }, {});

        setRecentErrors(Object.values(errors));
      });

    return () => unsubscribe();
  }, [
    interval,
    project.id,
  ]);

  return (
    <CenteredContent>
      <div style={{ marginBottom: 32, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h4">Monitoring</Typography>
          <Typography variant="body2">Verify all your integrations are working reliably</Typography>
        </div>
        <div>
          <TextField select label="View" value={interval} onChange={(event) => setInterval(event.target.value)}>
            <MenuItem value="Minute">Last 15 Minutes</MenuItem>
            <MenuItem value="Hour">Last 24 Hours</MenuItem>
            <MenuItem value="Day">Last Month</MenuItem>
            <MenuItem value="Month">Last Year</MenuItem>
          </TextField>
        </div>
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" style={{ margin: '32px 0 16px '}}>Page Loads</Typography>
          <Typography variant="h2" color="default">{ totalPageLoads || '--' }</Typography>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" style={{ margin: '32px 0 16px '}}>Show Rate</Typography>
          <Typography variant="h2" color="primary">{ totalPageLoads ? `${averageIntegrationShowRate}%` : '--' }</Typography>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h5" style={{ margin: '32px 0 16px '}}>Error Rate</Typography>
          <Typography variant="h2" color="error">{ totalPageLoads ? `${averageIntegrationErrorRate}%` :'--' }</Typography>
        </div>
      </div>
      { !!recentErrors.length && (
        <Fragment>
          <Divider style={{ margin: '32px 0' }} />
          <Typography variant="h5" style={{ marginBottom: 16 }}>Top Errors</Typography>
          <Card>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Integration
                  </TableCell>
                  <TableCell>
                    Error
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { recentErrors.map(error => <ErrorRow error={error} integrations={integrations} />) }
              </TableBody>
            </Table>
          </Card>
        </Fragment>
      ) }
      <Divider style={{ margin: '32px 0 32px' }} />
      <Typography variant="h5">Integrations</Typography>
      { Object.keys(integrationsById).map(integrationName => {
        return (
          <Fragment>
            <Typography variant="caption" style={{ margin: '16px 0 8px', display: 'block' }}>{ integrationName }</Typography>
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Site
                    </TableCell>
                    <TableCell>
                      Shows
                    </TableCell>
                    <TableCell>
                      Errors
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { integrationsById[integrationName].map(integration => (
                    <IntegrationRow integration={integration} interval={interval} />
                  )) }
                </TableBody>
              </Table>
            </Card>
          </Fragment>
        );
      }) }
    </CenteredContent>
  );
}
