import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firestore, auth } from '../firebase';

export const getAllProjects = createAsyncThunk(
  'projects/getAll',
  async () => {
    const { docs } = await firestore.collection('Projects')
      .where('ownerId', '==', auth.currentUser.uid)
      .get();

    return docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },
)

export const createProject = createAsyncThunk(
  'projects/create',
  async (project) => {
    const res = await firestore.collection('Projects').add({ ...project, ownerId: auth.currentUser.uid });

    return { id: res.id, ...project, ownerId: auth.currentUser.uid };
  }
);

export default createSlice({
  name: 'projects',
  initialState: {
    projectId: null,
    all: [],
    cachedAt: null,
    pending: false,
  },
  reducers: {
    selectProject: (state, action) => {
      state.projectId = action.payload;
    },
  },
  extraReducers: {
    [getAllProjects.pending]: (state) => {
      state.pending = true;
    },
    [getAllProjects.fulfilled]: (state, action) => {
      state.all = action.payload;
      state.cachedAt = Date.now();
      state.pending = false;

      if (!state.projectId && action.payload.length) {
        state.projectId =  action.payload[0].id;
      }
    },
    [getAllProjects.rejected]: (state) => {
      state.pending = false;
    },
    [createProject.fulfilled]: (state, action) => {
      state.all.push(action.payload);
    }
  }
});
