import { Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

export default function SiteForm({ form }) {
  return (
    <div style={{ display: 'grid', gridGap: 16 }}>
      <Controller
        name="name"
        control={form.control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            {...field}
            label="Site Name"
            variant="outlined"
            helperText="The name of the website this integration will run on. You add multiple integrations to a site."
            error={!!form.formState.errors.domains}
          />
        )}
      />
      <Controller
        name="domains"
        control={form.control}
        rules={{ required: true }}
        render={({ field }) => <TextField
          {...field}
          label="Domains"
          rows={4}
          variant="outlined"
          multiline
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          placeholder="e.g. www.google.com"
          helperText="Domains this website appears on. Separate domains by new lines."
          error={!!form.formState.errors.domains}
        />}
      />
    </div>
  );
}
