import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { firestore, auth } from '../firebase';

export const getIntegrationsByProject = createAsyncThunk(
  'integrations/fetchByProject',
  async ({ projectId }) =>  {
    const { docs } = await firestore.collection('Integrations')
      .where('projectId', '==', projectId)
      .where('ownerId', '==', auth.currentUser.uid)
      .get();

    return docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } 
);

export const deleteIntegrationsByProject = createAsyncThunk(
  'integrations/delete',
  async ({integrationId, projectId }) =>  {
    return firestore.collection('Integrations')
      .doc(integrationId)
      .delete();
  }
);


export const saveIntegration = createAsyncThunk(
  'integrations/save',
  async (integration) => {
    if (integration.id) {
      await firestore.collection('Integrations').doc(integration.id).set(integration, { merge: true });

      return integration;
    }

    const res = await firestore.collection('Integrations').add({ ownerId: auth.currentUser.uid, ...integration });

    return { id: res.id, ...integration, ownerId: auth.currentUser.uid };
  }
);

export default createSlice({
  name: 'integrations',
  initialState: {
    byProject: {},
  },

  extraReducers: {
    [getIntegrationsByProject.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.byProject[action.payload[0].projectId] = { cachedAt: Date.now(), all: [] };
      }

      action.payload.forEach((integration) => {
        state.byProject[integration.projectId].all.push(integration);
      });
    },

    [getIntegrationsByProject.rejected]: (state, action) => {
      console.error('failed to get integrations', action);
    },

    [saveIntegration.fulfilled]: (state, action) => {
      const integration = action.payload;
      const projectId = integration.projectId;
      const integrations = state.byProject[projectId] = state.byProject[projectId] || { cachedAt: null, all: [] };
      let isNew = true;

      integrations.all = integrations.all.map(existingIntegration => {
        if (integration.id === existingIntegration.id) {
          isNew = false;
          return integration;
        }

        return existingIntegration;
      });

      if (isNew) {
        integrations.all.push(integration);
      }
    },
    [saveIntegration.rejected]: (state, action) => {
      console.error('rejected integration', action);
    },
    [deleteIntegrationsByProject.fulfilled]: (state, action) => {
      state.byProject[action.meta.arg.projectId].all.forEach((integration, index) => {
        if (integration.id === action.meta.arg.integrationId) {
          state.byProject[action.meta.arg.projectId].all.splice(index, 1);
        }
      });
      console.log('Success', action);
    },
    [deleteIntegrationsByProject.rejected]:(state, action) => {
      console.error('Delete', action);
    }
  }
})
