import algoliasearch from 'algoliasearch';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const algoliaclient = algoliasearch('NS43WCK179', '26d7bc1730a82fafd57fad202340c6c4');
const indexes = {
  TestStep: algoliaclient.initIndex('TestStep'),
  Transform: algoliaclient.initIndex('Transform'),
  Source: algoliaclient.initIndex('Source'),
  Anchor: algoliaclient.initIndex('Anchor'),
  IntegrationTrigger: algoliaclient.initIndex('IntegrationTrigger'),
};

export const searchBlocks = createAsyncThunk(
  'blocks/search',
  ({ term, source } = {}) => indexes[source].search(term).then(({ hits }) => hits)
);

export default createSlice({
  name: 'blocks',
  initialState: {
    cachedAt: null,
    isLoading: false,
    byId: {},
  },
  extraReducers: {
    [searchBlocks.pending]: (state, action) => {
      state.isLoading = true;
    },
    [searchBlocks.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.cachedAt = Date.now();
      action.payload.forEach((block) => {
        state.byId[block.name] = state.byId[block.name] || [];
        state.byId[block.name] = block;
      });
    },
    [searchBlocks.rejected]: (state, action) => {
      state.isLoading = false;
      state.cachedAt = Date.now();
    }
  }
})
