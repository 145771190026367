import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

export default function IntegrationStepper({ activeStep }) {
  const stepNumbers = {
    integration: 0,
    trigger: 1,
    parser: 2,
    setup: 3
  };

  return (
    <Stepper activeStep={stepNumbers[activeStep]}>
      <Step key="integration" completed={stepNumbers[activeStep] > 0}>
        <StepLabel>Create Integration</StepLabel>
      </Step>
      <Step key="trigger" completed={stepNumbers[activeStep] > 1}>
        <StepLabel>Set Trigger</StepLabel>
      </Step>
      <Step key="parser" completed={stepNumbers[activeStep] > 2}>
        <StepLabel>Add Parser</StepLabel>
      </Step>
      <Step key="setup" completed={stepNumbers[activeStep] > 3}>
        <StepLabel>Setup Code</StepLabel>
      </Step>
    </Stepper>
  );
}

