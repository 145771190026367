import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Divider from '@material-ui/core/Divider';
import TableContainer from '@material-ui/core/TableContainer';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import WidgetsIcon from '@material-ui/icons/Widgets';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';

import useCurrentProject from '../../hooks/useCurrentProject';
import useSites from '../../hooks/useSites';
import Link from '../../components/Link';
import { useHistory, useParams } from 'react-router';
import useParsers from '../../hooks/useParsers';
import useIntegrations from '../../hooks/useIntegrations';
import useAnchors from '../../hooks/useAnchors';
import StepBuilder from '../../components/StepBuilder';
import {Controller, useForm} from 'react-hook-form';
import {saveIntegration} from '../../slices/integrations';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { deleteParsersByProject } from '../../slices/parsers';
import { deleteAnchorsByProject } from '../../slices/anchors';

function Parsers({ projectId, integrationId }) {
  const history = useHistory();
  const parsers = useParsers({ projectId, integrationId });
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();

  function getStatus({ lastTest }) {
    if (!lastTest) { return '--'; }

    if (lastTest.error) {
      return 'Failing';
    }

    return 'Healthy';
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CenterFocusWeakIcon style={{ fontSize: 52 }} />
          <div style={{ marginLeft: 16 }}>
            <Typography variant="h5">Parsers</Typography>
            <Typography variant="body2">A Parser tells your Extension how to read data when this Integration finds relevant HTML</Typography>
          </div>
        </div>
      </div>
      <Card style={{ marginTop: 16 }}>
        <div>
          { !!parsers.length && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsers.map(parser => {
                    return (
                      <TableRow>
                        <TableCell>
                          <Link to={`/parsers?parserId=${parser.id}&projectId=${parser.projectId}&integrationId=${parser.integrationId}`}>
                            {parser.name}
                          </Link>
                        </TableCell>
                        <TableCell>{ getStatus(parser) }</TableCell>
                        <TableCell>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => dispatch(deleteParsersByProject({ parserId: parser.id, projectId: parser.projectId }))}
                            >Delete
                          </Button>
                      </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) }
          { parsers.length === 0 && (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 16 }}>
              <Typography variant="h5">Nothing here</Typography>
              <Typography variant="body2">Create a new Parser to get started</Typography>
            </div>
          ) }
        </div>
      </Card>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginTop: 16 }}
          startIcon={<AddIcon />}
          onClick={() => {
            history.push({
              pathname: '/parsers',
              search: `?projectId=${currentProject.id}&integrationId=${integrationId}`,
            });
          }}>
          Add Parser
        </Button>
      </div>
    </div>
  );
}

function EditIntegration({ integration, projectId, site }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { integrationId } = useParams();
  const anchors = useAnchors({ integrationId, projectId });
  const form = useForm({
    name: 'integration',
    defaultValues: integration,
  });

  function resetForm() {
    form.reset(integration);
  }

  async function onSubmit(values) {
    await dispatch(saveIntegration(values));

    setIsExpanded(false);
  }

  return (
    <div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div style={{ margin: 32, minHeight: '100vh' }}>
          <div style={{ marginBottom: 32 }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link to="/">
                Integrations
              </Link>
              { site && <Typography color="textPrimary">{site.name}</Typography> }
              <Typography color="textPrimary">{integration.name}</Typography>
            </Breadcrumbs>
          </div>

          <div style={{
            pointerEvents: isExpanded ? undefined : 'none',
            position: 'relative',
          }}>
            <StepBuilder source="IntegrationTrigger" limitOne syntax="gherkin" form={form} disabled={!isExpanded} />
          </div>
          <div style={{ marginBottom: 32, textAlign: 'center', marginTop: 16 }}>
            <Button color="primary" onClick={() => setIsExpanded('trigger')} startIcon={<EditIcon/>}>Edit this Trigger</Button>
          </div>

          { !isExpanded && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Divider />
              <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 700 }}>
                <div style={{ flex: 1, marginTop: 32 }}>
                  <Parsers projectId={projectId} integrationId={integrationId} />
                </div>
                <div style={{ flex: 1, display: 'flex', marginTop: 64 }}>
                  <WidgetsIcon style={{ fontSize: 52 }} />
                  <div style={{ marginLeft: 16 }}>
                    <Typography variant="h5">Anchors</Typography>
                    <Typography variant="body2">An Anchor tells your Extension where to inject content when this Integration finds relevant HTML</Typography>
                  </div>
                </div>
                <Card style={{ marginTop: 16 }}>
                  { anchors.all.length ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { anchors.all.map(anchor => (
                          <TableRow>
                            <TableCell>
                              <Link to={`/anchors?anchorId=${anchor.id}&projectId=${anchor.projectId}&integrationId=${anchor.integrationId}`}>
                                {anchor.name}
                              </Link>
                            </TableCell>
                            <TableCell>
                              --
                            </TableCell>
                            <TableCell>
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => dispatch(deleteAnchorsByProject({ anchorId: anchor.id, projectId: anchor.projectId }))}
                                  >Delete
                              </Button>
                      </TableCell>
                          </TableRow>
                        )) }
                      </TableBody>
                    </Table>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 16 }}>
                      <Typography variant="h5">Nothing here</Typography>
                      <Typography variant="body2">Create a new Anchor to get started</Typography>
                    </div>
                  ) }
                </Card>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 16 }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => history.push({ pathname: '/anchors', search: `?integrationId=${integration.id}&projectId=${projectId}` })}
                    startIcon={<AddIcon />}
                  >
                    Add Anchor
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        { isExpanded && (
          <AppBar color="default" position="sticky" style={{ bottom: 0, width: '100%' }}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Controller
                  name="active"
                  control={ form.control }
                  render={({ field }) => (
                    <FormControlLabel
                      label="Active"
                      value="active"
                      control={<Switch label="Active" checked={field.value} onChange={field.onChange} />}
                    />
                  ) }
                />
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 8 }}>
                  <Button variant="outlined" type="button" onClick={() => {
                    resetForm();
                    setIsExpanded(false)
                  }}>Cancel</Button>
                </div>
                <Button color="primary" variant="contained" type="submit">Save</Button>
              </div>
            </Toolbar>
          </AppBar>
        ) }
      </form>
    </div>
  );
}

export default function IntegrationEditPage() {
  const integrations = useIntegrations();
  const project = useCurrentProject();
  const { integrationId } = useParams();
  const integration = integrations && integrations.all.find(i => i.id === integrationId);
  const sites = useSites();
  const site = sites.find(s => s.id === integration.siteId);
  const isLoading = integrations.isLoading || !project;

  if (isLoading) {
    return 'Loading...';
  }

  if (!integration || !site) {
    return 'Something went wrong. Try reloading the page.';
  }

  return <EditIntegration integration={integration} site={site} projectId={project.id} />
}
