import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import {useDispatch, useSelector} from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';

import useCurrentProject from '../../hooks/useCurrentProject';
import useSites from '../../hooks/useSites';
import {createSite} from '../../slices/sites';
import { saveIntegration } from '../../slices/integrations';
import StepBuilder from '../../components/StepBuilder';
import SiteForm from '../../components/SiteForm';
import CenteredContent from '../../components/CenteredContent';
import useQuery from '../../hooks/useQuery';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CodeEditor from '../../components/CodeEditor';
import IntegrationStepper from '../../components/IntegrationStepper';

function SiteFormDialog({ close, onCreated }) {
  const form = useForm({ mode: 'onSubmit' });
  const dispatch = useDispatch();
  const currentProject = useCurrentProject();

  async function onSubmit(values) {
    const siteValues = { ...values, domains: values.domains.split(/\s/g), projectId: currentProject.id };
    const action = await dispatch(createSite(siteValues));

    onCreated(action.payload.id);
  }

  return (
    <Dialog open onClose={ close }>
      <DialogTitle>Setup Site</DialogTitle>
      <DialogContent>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <SiteForm form={form} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={form.formState.isSubmitting} type="submit" onClick={form.handleSubmit(onSubmit)}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

export function IntegrationForm({ integration, siteId }) {
  const [isAddingSite, setIsAddingSite] = useState(false);
  const isLoading = useSelector(state => state.projects.pending);
  const dispatch = useDispatch();
  const project = useCurrentProject();
  const sites = useSites();
  const site = sites.find(site => site.id === siteId);
  const history = useHistory();
  const form = useForm({
    name: 'integration',
    mode: 'onSubmit',
    defaultValues: {
      active: true,
      name: integration ? integration.name : '',
      siteId: siteId || (integration ? integration.siteId : ''),
      steps: [{ id: uuid(), __type: 'block' }],
    },
  });

  async function handleSubmit(values) {
    const newValues = {
      ...integration,
      active: true,
      projectId: project.id,
      name: values.name,
      siteId: values.siteId,
      steps: values.steps,
    };

    const res = await dispatch(saveIntegration(newValues));

    history.push({ pathname: `/integrations/${res.payload.id}`, search: `projectId=${project.id}` });
  }

  if (isLoading || !project) {
    return 'Loading...';
  }

  const integrationName = form.watch('name');

  const integrationCode =
`extellerate.extend('${integrationName}', (context) => {
  console.log('Now running integration "${integrationName}" with', context);
});`;

  return (
    <div>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <CenteredContent style={{ minHeight: '100vh' }}>
          <div style={{ display: 'grid', gridGap: '16px' }}>
            <Typography variant="h4">Add an Integration { site && ` for ${site.name}`}</Typography>
            <Typography variant="subtitle1">Create an integration for each section of a site that you want your Extension to interact with.</Typography>
            <Controller
              name="name"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  error={form.formState.errors.name}
                  {...field}
                  variant="outlined"
                  helperText="You will use this name later to build this Integration into your Extension"
                  label="Name"
                />
              ) }
            />
            <Controller
              name="siteId"
              control={form.control}
              rules={{ required: true }}
              render={({ field }) => siteId ? <input type="hidden" {...field} /> : (
                <TextField
                  error={form.formState.errors.siteId}
                  select
                  label="Site"
                  variant="outlined"
                  helperText="The Website your integration will run on. Click the box above and select Add Site if you haven't created a Site yet."
                  style={{ minWidth: 300 }}
                  {...field}
                >
                  {sites.map(site => (
                    <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                  ))}
                  <Divider style={{ margin: '8px 0' }} />
                  <MenuItem value={null} onClick={ () => setIsAddingSite(true) }>
                    Add Site
                  </MenuItem>
                </TextField>
              )}
            >
            </Controller>
            <div style={{ flex: 1, display: 'flex', marginTop: 64 }}>
              <PowerSettingsNewIcon style={{ fontSize: 52 }} />
              <div style={{ marginLeft: 16 }}>
                <Typography variant="h5">Setup Trigger</Typography>
                <Typography variant="body2">When should your extension use this integration</Typography>
              </div>
            </div>
            <StepBuilder source="IntegrationTrigger" syntax="gherkin" form={form} limitOne />

            { integrationName && (
              <Fragment>
                <div style={{ flex: 1, display: 'flex', marginTop: 64, marginBottom: 16 }}>
                  <FileCopyIcon style={{ fontSize: 52 }} />
                  <div style={{ marginLeft: 16 }}>
                    <Typography variant="h5">Copy Integration Code</Typography>
                    <Typography variant="body2">Paste the code below to the bottom of your extension's content script</Typography>
                  </div>
                </div>
                <CodeEditor value={integrationCode} readOnly />
              </Fragment>
            ) }

          </div>
        </CenteredContent>
        <AppBar color="inherit" position="sticky" style={{ bottom: 0, width: '100%' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IntegrationStepper activeStep="integration" />
            <div>
              <Button
                variant="outlined"
                onClick={() => history.push(`/integrations?projectId=${project.id}`) }
                style={{ marginRight: 8 }}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" color="primary">Next</Button>
            </div>
          </Toolbar>
        </AppBar>
      </form>
      { isAddingSite && (
        <SiteFormDialog
          close={ () => setIsAddingSite(false) }
          onCreated={ id => {
            form.setValue('siteId', id);
            setIsAddingSite(false);
          }}
        />
      ) }
    </div>
  );
}

export default function CreateIntegration() {
  const query = useQuery();
  const siteId = query.get('siteId');

  return (
    <IntegrationForm siteId={siteId} />
  );
}
