import { configureStore } from '@reduxjs/toolkit';
import anchorsSlice from './slices/anchors';
import blocksSlice from './slices/blocks';
import projectsSlice from './slices/projects';
import parsersSlice from './slices/parsers';
import sitesSlice from './slices/sites';
import userSlice from './slices/user';
import integrationsSlice from './slices/integrations';
import testsSlice from './slices/tests';
import scriptsSlice from './slices/scripts';

export default configureStore({
  reducer: {
    anchors: anchorsSlice.reducer,
    blocks: blocksSlice.reducer,
    parsers: parsersSlice.reducer,
    projects: projectsSlice.reducer,
    sites: sitesSlice.reducer,
    user: userSlice.reducer,
    integrations: integrationsSlice.reducer,
    tests: testsSlice.reducer,
    scripts: scriptsSlice.reducer,
  },
});
