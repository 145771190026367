import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useCurrentProject from './useCurrentProject';
import { getIntegrationsByProject } from '../slices/integrations';

export default function useIntegrations({ siteId } = {}) {
  const project = useCurrentProject();
  const integrationsByProject = useSelector(state => state.integrations.byProject[project && project.id]);
  const isLoading = !integrationsByProject || !integrationsByProject.cachedAt;
  const dispatch = useDispatch();
  const projectId = project && project.id;

  useEffect(() => {
    if (projectId) {
      dispatch(getIntegrationsByProject({ projectId }));
    }
  }, [dispatch, projectId]);

  let integrations = integrationsByProject ? integrationsByProject.all : [];

  if (siteId) {
    integrations = integrations.filter(integration => integration.siteId === siteId)
  }

  return {
    isLoading: isLoading,
    all: integrations,
  };
}
