import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { firestore, auth } from '../firebase';

export const getTests = createAsyncThunk(
  'tests/getAll',
  async ({ integrationId }) => {
    const { docs } = await firestore.collection('Integrations')
      .doc(integrationId)
      .collection('Tests')
      .where('ownerId', '==', auth.currentUser.uid)
      .get();

    return docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },
);

export const saveTest = createAsyncThunk(
  'tests/save',
  async (test)  => {
    const collection = firestore.collection('Integrations')
      .doc(test.integrationId)
      .collection('Tests');

    if (test.id) {
      await collection.doc(test.id).set(test, { merge: true });

      return test;
    }

    const res = await collection.add({ ownerId: auth.currentUser.uid, ...test });

    return { id: res.id, ...test };
  }
);

export const deleteTest = createAsyncThunk(
  'tests/delete',
  async (test) => {
    await firestore.collection('Integrations')
      .doc(test.integrationId)
      .collection('Tests')
      .doc(test.id)
      .delete();
  },
);

export default createSlice({
  name: 'tests',
  initialState: {
    byIntegration: {},
  },
  extraReducers: {
    [getTests.fulfilled]: (state, action) => {
      state.byIntegration[action.meta.arg.integrationId] = {
        isLoading: false,
        cachedAt: Date.now(),
        all: action.payload
      };
    },
    [getTests.rejected]: (state, action) => {
      console.error('failed to fetch tests', action);
    },
    [saveTest.fulfilled]: (state, action) => {
      state.byIntegration[action.payload.integrationId] = state.byIntegration[action.payload.integrationId] || {
        isLoading: false,
        all: [],
      };

      state.byIntegration[action.payload.integrationId].cachedAt = Date.now()
      state.byIntegration[action.payload.integrationId].all.push(action.payload);
    },
    [saveTest.rejected]: (state, action) => {
      console.error('failed to save test', action);
    },
    [deleteTest.fulfilled]: (state, action) => {
      const integrationId = action.meta.arg.integrationId;
      const testId = action.meta.arg.id;

      state.byIntegration[integrationId].all = state.byIntegration[integrationId].all.filter(test => test.id !== testId);
    },
    [deleteTest.rejected]: (state, action) => {
      console.error('test delete rejected', action);
    }
  },
});
