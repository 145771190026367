import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import useQuery from '../../hooks/useQuery';
import useCurrentProject from '../../hooks/useCurrentProject';
import useParsers from '../../hooks/useParsers';
import {createParser} from '../../slices/parsers';
import {saveScript} from '../../slices/scripts';
import StepBuilder from '../../components/StepBuilder';
import useIntegrations from '../../hooks/useIntegrations';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Link from '../../components/Link';
import Typography from '@material-ui/core/Typography';
import useSites from '../../hooks/useSites';

const useStyles = makeStyles((theme) => ({
  bottomBar: {
    width: '100%',
    bottom: 0,
  },
}));

function Form({ project, parser, integrationId }) {
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const projectId = project.id;
  const integrations = useIntegrations(project.id);
  const integration = integrations.all.find(integration => integration.id === integrationId);
  const sites = useSites();
  const site = sites.find(s => s.id === integration.siteId);
  const { debugError } = history.location.state || {};
  const classes = useStyles();

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: JSON.parse(JSON.stringify({
      active: !!(parser && parser.active),
      name: (parser && parser.name) || '',
      siteId: (parser && parser.siteId) || '',
      testUrl: (parser && parser.testUrl) || '',
      steps: (parser && parser.steps) || [{ __type: 'block', name: '', id: uuid() }],
    })),
  });

  const parserName = form.watch('name');

  if (!integration || !site ) {
    return 'Loading...';
  }

  function saveScripts(steps) {
    return Promise.all(steps.map(async (step) => {
      if (step.name === 'JavaScriptTransform') {
        const { payload } = await dispatch(saveScript({
          id: step.args.scriptId,
          projectId: integration.projectId,
          integrationId: integration.id,
          source: step.args.script,
        }));


        step.args.scriptId = payload.id;
      }

      return step;
    }));
  }

  function getParser(values) {
    const id = query.get('parserId');
    const parser =  { ...values, projectId: project.id, integrationId: query.get('integrationId') };

    if (id) {
      parser.id = id;
    }

    return parser;
  }

  async function handleSubmit(values) {
    const parser = getParser(values);

    await saveScripts(parser.steps);

    const { error } = await dispatch(createParser(parser));

    if (error) {
      alert('Failed to save');
      return;
    }

    history.push({ pathname: `/integrations/${query.get('integrationId')}`, search: `?projectId=${project.id}` });
  }

  return (
    <div>
      <div style={{ margin: 32, display: 'flex', justifyContent: 'space-between' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`/integrations?projectId=${projectId}`}>
            Integrations
          </Link>
          { site && <Typography color="textPrimary">{site.name}</Typography> }
          <Link to={`/integrations/${integrationId}?projectId=${projectId}`}>{integration.name}</Link>
          <Typography color="textPrimary">Parsers</Typography>
          <Typography color="textPrimary">{parserName}</Typography>
        </Breadcrumbs>
      </div>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'space-between' }}>
          <div style={{ padding: '48px 16px 0', display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: 700, width: '100%' }}>
              <Controller
                name="name"
                defaultValue=""
                rules={{ required: true }}
                control={form.control}
                render={({ field }) => (
                  <TextField
                    error={!!form.formState.errors.name}
                    label="Parser Name"
                    variant="outlined"
                    style={{ minWidth: 300, marginRight: 16 }}
                    helperText={
                      <span>
                        Use this name to invoke this Parser in your extension using the Extellerate SDK.
                        &nbsp;<Link to="/help/parser-integration">Here's how.</Link>
                      </span>
                    }
                    fullWidth
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 1, width: '100%', padding: '32px 16px' }}>
            <StepBuilder form={form} source="Transform" initialSource="Source" debugError={debugError} />
          </div>
        </div>
        <AppBar position="sticky" color="default" className={ classes.bottomBar }>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Controller
                name="active"
                control={ form.control }
                render={({ field }) => (
                  <FormControlLabel
                    label="Active"
                    value="active"
                    control={<Switch label="Active" checked={field.value} onChange={field.onChange} />}
                  />
                ) }
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 8 }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => history.push(`/integrations/${integrationId}?projectId=${projectId}`)}
                >
                  Cancel
                </Button>
              </div>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </form>
    </div>
  );
}

export default function EditTask() {
  const query = useQuery();
  const parserId = query.get('parserId');
  const integrationId = query.get('integrationId');
  const currentProject = useCurrentProject();
  const parsers = useParsers({ projectId: currentProject && currentProject.id });
  const parser = useMemo(() => parsers.find(parser => parser.id === parserId), [parsers, parserId]);

  if (!currentProject || (parserId && !parser)) {
    return 'Loading...';
  }

  return <Form project={ currentProject } parser={ parser } integrationId={integrationId} />;
}
