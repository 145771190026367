import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAnchorsByProject } from '../slices/anchors';

export default function useAnchors({ projectId, integrationId }) {
  let anchors = useSelector(state => state.anchors.byProject[projectId] && state.anchors.byProject[projectId].all) || [];
  const anchorsCachedAt = useSelector(state => state.anchors.byProject[projectId] && state.anchors.byProject[projectId].cachedAt);
  const dispatch = useDispatch();

  useEffect(() => {
    if (anchorsCachedAt || !projectId) { return; }

    dispatch(getAnchorsByProject({ projectId }));
  }, [anchorsCachedAt, projectId, dispatch]);

  if (integrationId && anchors) {
    anchors = anchors.filter(anchor => anchor.integrationId === integrationId);
  }

  return { all: anchors, cachedAt: anchorsCachedAt };
}
