import firebase from 'firebase';

const app = firebase.apps[0] || firebase.initializeApp({
  apiKey: "AIzaSyBzaCCUgn7db4j2eY1w5zDqVObLLdFleYE",
  authDomain: "extellerate.firebaseapp.com",
  projectId: "extellerate",
  storageBucket: "extellerate.appspot.com",
  messagingSenderId: "251953683656",
  appId: "1:251953683656:web:d6688c1aad4f44f540d9fd",
  measurementId: "G-H1SXXBXZK2"
});

const _firestore = app.firestore();
const _functions = app.functions();
const _auth = app.auth();

const useEmulator = process.env.REACT_APP_USE_EMULATOR;

if (useEmulator) {
  _auth.useEmulator('http://localhost:9099/');
  _firestore.useEmulator('localhost', 8084);
  _functions.useEmulator('localhost', 5001);
}

export const firestore = _firestore;
export const functions = _functions;
export const auth = _auth;
