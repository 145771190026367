import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { v4 as uuid } from 'uuid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import StepBuilder from './StepBuilder';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {functions} from '../firebase';
import CenteredContent from './CenteredContent';
import {saveIntegration} from '../slices/integrations';
import {useHistory} from 'react-router';

const testIntegration = functions.httpsCallable('testIntegration');

export default function TestCaseBuilder({ test, integration, projectId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const form = useForm({
    name: 'test',
    mode: 'onSubmit',
    defaultValues: {
      steps: (test && test.steps) || [{ id: uuid(), __type: 'block', name: 'GoTo' }],
    },
  });

  async function onSubmit(values) {
    const safeValue = JSON.parse(JSON.stringify(values));
    const res = await dispatch(saveIntegration({ test: safeValue, id: integration.id, projectId }));

    console.log('res', res);

    history.push(`/monitoring?projectId=${projectId}`);
  }

  async function runTest(values) {
    const result = await testIntegration({ testSteps: values.steps, integration });

    alert(JSON.stringify(result));
  }

  return (
    <form onSubmit={ form.handleSubmit(onSubmit) }>
      <CenteredContent>
        <div style={{ minHeight: '100vh' }}>
          <Typography variant="h4">Setup Test</Typography>
          <Typography variant="body2">In order to monitor this integration, list the steps to find an example of this integration.</Typography>
          <StepBuilder form={form} source="TestStep" title="Test Steps" />
        </div>
      </CenteredContent>
      <AppBar position="sticky" color="default" style={{ width: '100%', bottom: 0 }}>
        <Toolbar style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ marginRight: 8 }}>
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              onClick={() => runTest(form.getValues())}
            >
              Run Test
            </Button>
          </div>
          <Button color="primary" variant="contained" type="submit">Save</Button>
        </Toolbar>
      </AppBar>
    </form>
  );
}
