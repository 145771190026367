import useIntegrations from '../../../hooks/useIntegrations';
import useQuery from '../../../hooks/useQuery';
import TestCaseBuilder from '../../../components/TestCaseBuilder';

export default function TestCasePage() {
  const query = useQuery();
  const projectId = query.get('projectId');
  const integrations = useIntegrations({ projectId });
  const integration = integrations.all.find(integration => integration.id === query.get('integrationId'));
  const isLoading = integrations.isLoading;

  if (isLoading) {
    return 'Loading...';
  }

  if (!integration) {
    return 'Could not find Integration';
  }

  return <TestCaseBuilder integration={ integration } test={integration.test} projectId={ projectId } />;
}
