import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getParsersByProject } from '../slices/parsers';

export default function useParsers({ projectId, integrationId }) {
  const parsers = useSelector(state => state.parsers.byProject[projectId] && state.parsers.byProject[projectId].all);
  const parsersCachedAt = useSelector(state => state.parsers.byProject[projectId] && state.parsers.byProject[projectId].cachedAt)
  const dispatch = useDispatch();

  useEffect(() => {
    if (parsersCachedAt || !projectId) { return; }

    dispatch(getParsersByProject({ projectId }));
  }, [parsersCachedAt, projectId, dispatch]);

  if (integrationId && parsers) {
    return parsers.filter(parser => parser.integrationId === integrationId);
  }

  return parsers || [];
}
