import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import useQuery from '../../hooks/useQuery';
import StepBuilder from '../../components/StepBuilder';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import useAnchors from '../../hooks/useAnchors';
import useIntegrations from '../../hooks/useIntegrations';
import useSites from '../../hooks/useSites';
import { saveAnchor } from '../../slices/anchors';
import Link from '../../components/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';

function AnchorForm({ anchor, integration, site, projectId }) {
  const history = useHistory();
  const integrationId = integration.id;
  const dispatch = useDispatch();
  const form = useForm({
    name: 'anchor',
    defaultValues: {
      active: true,
      name: '',
      steps: [{ id: uuid(), __type: 'block' }],
      ...anchor,
    }
  });

  function onSubmit(values) {
    dispatch(saveAnchor({
      ...values,
      projectId,
      integrationId,
    }));

    history.replace(`/integrations/${integrationId}?projectId=${projectId}`);
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div style={{ margin: 32 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={`/integrations?projectId=${projectId}`}>
            Integrations
          </Link>
          { site && <Typography color="textPrimary">{site.name}</Typography> }
          <Link to={`/integrations/${integrationId}?projectId=${projectId}`}>{integration && integration.name}</Link>
          <Typography color="textPrimary">Anchors</Typography>
          <Typography color="textPrimary">{form.watch('name')}</Typography>
        </Breadcrumbs>
      </div>
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ padding: 32, maxWidth: 700, width: '100%' }}>
          <Controller
            name="name"
            control={form.control}
            rules={{ required: true }}
            render={({ field, fieldState  }) => (
              <TextField
                {...field}
                fullWidth
                error={fieldState.error}
                label="Name"
                helperText="The name you will use to get a reference to this anchor from your extension"
              />
            )}
          />
          <StepBuilder source="Anchor" form={form} limitOne />
        </div>
        <AppBar color="default" position="sticky" style={{ bottom: 0, width: '100%' }}>
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Controller
                name="active"
                control={ form.control }
                render={({ field }) => (
                  <FormControlLabel
                    label="Active"
                    value="active"
                    control={<Switch label="Active" checked={field.value} onChange={field.onChange} />}
                  />
                ) }
              />
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 8 }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => history.push(`/integrations/${integrationId}?projectId=${projectId}`)}
                >
                  Cancel
                </Button>
              </div>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </form>
  )
}

export default function Anchors() {
  const query = useQuery();
  const projectId = query.get('projectId');
  const integrationId = query.get('integrationId');
  const anchorId = query.get('anchorId');
  const { all: anchors } = useAnchors({ projectId, integrationId });
  const anchor = anchors.find(a => a.id === anchorId);
  const integrations = useIntegrations(projectId);
  const integration = integrations.all.find(integration => integration.id === integrationId);
  const sites = useSites();
  const site = sites.find(s => s.id === integration.siteId);

  if (!integration || !site || (!anchor && anchorId)) {
    return 'Loading...';
  }

  return <AnchorForm anchor={anchor} site={site} integration={integration} projectId={projectId} />
}
