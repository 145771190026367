import { useEffect } from 'react';
import useQuery from './useQuery';
import { useSelector, useDispatch } from 'react-redux';
import useProjects from './useProjects';
import projectsSlice from '../slices/projects';

const persistedProjectId = global.localStorage && global.localStorage.currentProjectId;

export default function useCurrentProject() {
  const projects = useProjects();
  const dispatch = useDispatch();
  const query = useQuery();
  const currentProjectId = useSelector(state => state.projects.projectId);
  const currentProject = projects.find(project => project.id === currentProjectId);
  const projectIdParam = query.get('projectId');

  useEffect(() => {
    if (currentProjectId !== projectIdParam && projectIdParam) {
      dispatch(projectsSlice.actions.selectProject(projectIdParam));
    }
  }, [currentProjectId, projectIdParam, dispatch]);

  useEffect(() => {
    const projectExists = !!projects.find(({ id }) => projectIdParam === id);

    if (projectIdParam && projectIdParam !== persistedProjectId && projectExists) {
      global.localStorage.currentProjectId = projectIdParam;
    } else {
      delete global.localStorage.currentProjectId;
    }
  }, [currentProjectId, projectIdParam, projects]);

  return currentProject;
}
