import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

export default function JSEditor({ name, onChange, value, inputRef, readOnly }) {
  return (
    <div style={{ background: '#fff' }}>
      <AceEditor
        onLoad={editor => {
          editor.renderer.setPadding(16);
          editor.renderer.setScrollMargin(16);
        }}
        style={{ height: 150, width: '100%' }}
        fontSize={14}
        mode="javascript"
        ref={inputRef}
        name={name}
        onChange={onChange}
        value={value || ''}
        padding={10}
        readOnly={readOnly}
        showGutter={!readOnly}
        highlightActiveLine={!readOnly}
        enableBasicAutocomplete
        theme="monokai"
      />
    </div>
  );
}
