import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import semver from 'semver';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { createProject } from '../slices/projects';
import { functions } from '../firebase';
import {useEffect} from 'react';

const getSdkVersions = functions.httpsCallable('getSdkVersions');

export default function ProjectForm({ close, isFirst }) {
  const [sdkVersions, setSdkVersions] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { reset, formState, control, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      sdkVersion: '',
    },
  });

  useEffect(() => {
    getSdkVersions().then(({ data }) => {
      data.sort(semver.rcompare);

      setSdkVersions(data)
      reset({ name: '', sdkVersion: data[0] })
    });
  }, [reset]);

  async function onSubmit(values) {
    const res = await dispatch(createProject(values));

    history.push({ pathname: '/sites/create', search: `?projectId=${res.payload.id}` });

    close();
  }

  return (
    <Dialog
      minWidth="lg"
      open
      onClose={close}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          { isFirst ? 'Hey there!' : 'New Extension' }
        </DialogTitle>
        <DialogContent>
          { isFirst ? (
            <p>Looks like this is your first Extension. What do you want to call it?</p>
          ) : null
          }
          <Controller
            name="name"
            control={control}
            rules={{ required: 'All successful extensions need a name!' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Extension Name"
                variant="outlined"
                fullWidth
                error={ formState.errors.name }
                helperText={ null }
              />
            ) }
          />
          <div style={{ marginTop: 16 }}>
            <Controller
              name="sdkVersion"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  label="Target SDK Version"
                  fullWidth
                  error={ formState.errors.sdkVersion }
                  helperText="If you don't know what this is, leave it as is."
                >
                  {sdkVersions.map((version) => (
                    <MenuItem key={version} value={version}>{version}</MenuItem>
                  ))}
                </TextField>
              ) }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Create</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
