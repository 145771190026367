import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Link from '../components/Link';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import AccountTree from '@material-ui/icons/AccountTree';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ProjectForm from './ProjectForm';
import useCurrentProject from '../hooks/useCurrentProject';
import useProjects from '../hooks/useProjects';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  }
}));

function ProjectDropDown() {
  const [anchor, setAnchor] = useState(null);
  const [canShowProjectForm, setCanShowProjectForm] = useState(false);
  const projects = useProjects();
  const currentProject = useCurrentProject();
  const history = useHistory();


  function onClick(event) {
    setAnchor(event.currentTarget);
  }

  function onExit() {
    setAnchor(null);
  }

  function onSelect(projectId) {
    history.push({ pathname: '/integrations', search: `?projectId=${projectId}` });
    setAnchor(null);
  }

  function createProject() {
    setAnchor(null);
    setCanShowProjectForm(true);
  }

  function hideProjectForm() {
    setCanShowProjectForm(false);
  }

  if (!projects.length) { return null; }

  return (
    <div>
      <Button onClick={ onClick }>
        <AccountTree />
        &nbsp;{ currentProject && currentProject.name }&nbsp;
        <KeyboardArrowDownIcon/>
      </Button>
      <Menu open={!!anchor} anchorEl={anchor} onClose={ onExit }>
        { projects.map((project) => (
          <MenuItem key={project.id} onClick={() => onSelect(project.id)}>{ project.name }</MenuItem>
        )) }
        <Divider />
        <MenuItem onClick={createProject}>Create New Project</MenuItem>
      </Menu>
      { canShowProjectForm && <ProjectForm close={hideProjectForm} /> }
    </div>
  );
}

export default function TopNav({ onMenuClick }) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={ classes.appBar } color="initial">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Hidden mdUp>
            <IconButton onClick={onMenuClick}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Link to="/">
            <Typography style={{ marginRight: 16 , cursor: 'pointer' }} variant="h6">Extellerate</Typography>
          </Link>
        </div>
        <ProjectDropDown />
      </Toolbar>
    </AppBar>
  );
}
