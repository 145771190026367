import {Fragment, useEffect, useState, useMemo} from 'react';
import { v4 as uuid } from 'uuid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Controller, useFieldArray} from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import StepIcon from '@material-ui/core/StepIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import useBlock from '../hooks/useBlock';
import CodeEditor from './CodeEditor';
import { searchBlocks } from '../slices/blocks';

function Step({ syntax, step, source, stepNumber, onRemove, form, error, disabled, debugError }) {
  const stepName = form.watch(`steps.${stepNumber}.name`);
  const block = useBlock(stepName, source);

  const schema = block && block.schema;

  const defaultStepTitles = {
    Transform: 'Choose a Transformation',
    TestStep: 'Choose a Step',
    Source: 'Choose a Source',
    Anchor: 'Choose a Source',
    IntegrationTrigger: 'Choose a Trigger',
  };

  const defaultSourceNames = {
    Transform: 'Transformations',
    TestStep: 'Test Steps',
    Source: 'Sources',
    Anchor: 'Sources',
    IntegrationTrigger: 'Triggers',
  };

  const defaultStepDescriptions = {
    Transform: 'This will transform the content from the previous step into a different format. This allows you to keep data from your integrations consistent across Sites.',
    TestStep: 'Find the next step in your process',
    Source: 'How should your Extension find this content on a page? Your Extension can parse content from each section your Integration finds.',
    Anchor: 'How should your Extension find this anchor on a page? Your Extension can find anchors in each section your Integration finds.',
    IntegrationTrigger: 'How should your Extension identify relevant HTML on this Site? Your Extension will be able to read and interact with all matching HTML.',
  };

  let stepTitle = (block && block.displayName) || defaultStepTitles[source];
  let stepDescription = (block && block.description) || defaultStepDescriptions[source];

  if (block && syntax === 'gherkin' && stepNumber === 0) {
    stepTitle = `When ${stepTitle}`;
  } else if (block && syntax === 'gherkin') {
    stepTitle = `And ${stepTitle}`;
  }

  const errors = form.formState.errors;
  const hasNameError = errors.steps && errors.steps[stepNumber] && errors.steps[stepNumber].name;
  const errorOccurrences = debugError && debugError.occurrences;
  const debugOccurrences = useMemo(
    () => errorOccurrences ? Array.from(new Set(errorOccurrences)) : [],
    [errorOccurrences]
  );

  return (
    <div
      key={step.id}
      style={{
        borderRadius: 8,
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        backgroundColor: '#fff',
        border: debugError ? '2px solid red' : undefined,
      }}
    >
      <div style={{ padding: 32 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px', marginBottom: 16 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <StepIcon icon={stepNumber + 1} />
            <div style={{ marginLeft: 32 }}>
              <Typography variant="h5">{ stepTitle }</Typography>
              <Typography variant="body2" style={{ margin: 0, fontSize: 12, color: '#777' }}>{ stepDescription }</Typography>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <IconButton type="button" color="default" onClick={onRemove}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          </div>
        </div>
        <Controller
          name={`steps.${stepNumber}.name`}
          control={ form.control }
          defaultValue={stepName}
          rules={{ required: true }}
          render={({ field }) => (
            <StepSearch
              sourceName={defaultSourceNames[source]}
              error={!!hasNameError}
              disabled={disabled}
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
              source={source}
            />
          )}
        />
        <input type="hidden" {...form.register(`steps.${stepNumber}.__type`)} defaultValue={step.__type} />
        <input type="hidden" {...form.register(`steps.${stepNumber}.id`)} defaultValue={step.id} />
        { schema && (<Schema path={`steps.${stepNumber}.args`} schema={ schema } form={form} disabled={disabled} debugError={debugError} />) }
        { error && (
          <div style={{ backgroundColor: 'red', color: '#fff', padding: 8 }}>
            { error.message }
          </div>
        ) }
      </div>
      { debugError && (
        <div style={{ backgroundColor: 'red', color: '#fff', padding: 32 }}>
          { debugError.message }.
          <Divider style={{ margin: '8px 0 8px' }} />
          Occurred on the following pages:
          <ul style={{ margin: 0, paddingLeft: 32 }}>
            { debugOccurrences.map(occurrence => (
              <li>
                <Link href={ occurrence.url } style={{ color: '#fff' }}>{ occurrence.url }</Link>
              </li>
            )) }
          </ul>
          <div>
            { debugOccurrences.length > 3 && `And ${debugOccurrences.length - 3} more...` }
          </div>
        </div>
      ) }
    </div>
  );
}

function FieldArraySchema({ path, form, field }) {
  const { fields, append, remove } = useFieldArray({ control: form.control, name: path });

  const columns = Object.values(field.schema.schema);
  const keys = Object.keys(field.schema.schema);
  const template = keys.reduce((acc, key) => ({ ...acc, [key]: '' }), {});

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            { columns.map(column => (
              <TableCell key={column.name}>
                <strong>{column.name}</strong>
                <p style={{ margin: '0 0 16px', color: '#777', fontSize: 12 }}>{column.description}</p>
              </TableCell>
            )) }
          </TableRow>
        </TableHead>
        <TableBody>
          { fields.map((field, index) => (
            <TableRow key={field.id}>
              { columns.map((column, columnIndex) => {
                const key = keys[columnIndex];
                const fieldPath = `${path}.${index}.${key}`;
                return (
                  <TableCell key={`${field.id}.${key}`}>
                    <Field
                      path={fieldPath}
                      field={column}
                      form={form}
                      noLabel
                      noDescription
                    />
                  </TableCell>
                );
              }) }
              <TableCell>
                <RemoveCircleOutlineIcon onClick={() => remove(index)} />
              </TableCell>
            </TableRow>
          )) }
        </TableBody>
      </Table>
      <div style={{ marginTop: 16 }}>
        <Button variant="outlined" color="secondary" type="button" onClick={() => append(Object.assign({id: 'zb' + uuid() }, template))}>Add Word</Button>
      </div>
    </div>
  )
}

function Field({ path, form, field, noLabel, noDescription, disabled }) {
  // This is fixes an issue where subsequent fields are cleared when a new step is inserted.
  // The default `undefined` value fixes a separate issue where the initial value becomes empty
  // when specifying the watched value as the default value when loading existing steps.
  // Setting as an empty string will cause fields to be empty when editing existing steps.
  const defaultValue = form.watch(path) || undefined;

  if (field.type === 'String') {
    return (
      <Controller
        name={path}
        control={ form.control }
        defaultValue={defaultValue}
        rules={{ required: !field.optional }}
        render={({ field: props, fieldState }) => (
          <TextField
            {...props}
            value={props.value || ''}
            disabled={disabled}
            label={!noLabel && field.name}
            helperText={!noDescription && field.description}
            fullWidth
            variant="outlined"
            error={fieldState.error}
            placeholder={field.example ?  `e.g. ${field.example}` : ''}
          />
        ) }
      />
    );
  } else if (field.type === 'TextArea') {
    return (
      <div>
        { !noLabel && <strong>{field.name}</strong> }
        { !noLabel && <p style={{ margin: '0 0 16px', color: '#777', fontSize: 12 }}>{field.description}</p> }
        <Controller
          name={path}
          control={ form.control }
          defaultValue={defaultValue}
          rules={{ required: !field.optional }}
          render={({ field, fieldState }) => (
            <CodeEditor
              name={field.name}
              disabled={disabled}
              fullWidth
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
              error={fieldState.error}
            />
          )}
        />
      </div>
    );
  } else if (field.type === 'FieldArray') {
    return (
      <FieldArraySchema
        path={path}
        form={form}
        field={field}
        disabled={disabled}
      />
    )
  } else if (field.type === 'Schema') {
    return (
      <Schema path={path} schema={ field.schema } form={ form } disabled={disabled} />
    )
  }

  return null;
}

function Schema({ path, schema, form, disabled }) {
  return (
    <Fragment>
      {Object.keys(schema.schema).map(key => {
        const field = schema.schema[key];

        return <Field form={form} key={path} field={field} path={`${path}.${key}`} disabled={disabled} />;
      })}
    </Fragment>
  )
}

function StepSearch({ source, sourceName, onChange, value, inputRef, disabled, error, ...rest }) {
  const [searchTerm] = useState('');
  const [matchingBlocks, setMatchingBlocks] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    dispatch(searchBlocks({ term: searchTerm, source })).then(({ payload }) => {
      if (!payload) {
        console.warn('Error getting matching blocks');
      }

      if (!isMounted) { return; }

      setMatchingBlocks(payload || []);
    });

    return () => {
      isMounted = false;
    }
  }, [searchTerm, dispatch, source]);

  if (value) {
    return null;
  }

  return (
    <div>
      {
        /**
         <div style={{ marginBottom: 16 }}>
         <TextField
         label={`Search ${sourceName}...`}
         disabled={disabled}
         error={error}
         variant="outlined"
         fullWidth
         onChange={(e) => setSearchTerm(e.target.value)}
         />
         <input type="hidden" value={value} ref={inputRef} {...rest} />
         </div>
         */
      }
      <List>
        { matchingBlocks.map((block, index) => (
          <ListItem button key={block.name} style={{ marginBottom: 8, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} onClick={() => onChange(block.name)}>
            <ListItemText primary={block.displayName} secondary={block.description} />
          </ListItem>
        )) }
      </List>
    </div>
  );
}

function StepConnector({ onClickAdd, disabled }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ backgroundColor: '#ccc', width: 2, position: 'relative', height: 16 }} />
      <IconButton color="secondary" style={{ marginBottom: '-12px', marginTop: '-12px' }} onClick={onClickAdd} disabled={disabled}>
        <AddCircleIcon />
      </IconButton>
      <div style={{ backgroundColor: '#ccc', width: 2, position: 'relative', height: 16 }} />
    </div>
  );
}

export default function StepBuilder({ form, source, initialSource, style, disabled, limitOne, debugError, syntax }) {
  const { insert, append, remove, fields } = useFieldArray({ control: form.control, name: 'steps' });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          flexDirection: 'column',
          marginTop: 16,
        }}
      >
        {fields.map((parserStep, index) => {
          const hasDebug = debugError && debugError.step === index + 1;

          return (
            <div key={parserStep.id} style={{ maxWidth: '700px', width: '100%' }}>
              <Step
                syntax={syntax}
                disabled={disabled}
                style={style}
                key={parserStep.id}
                source={(index === 0 && initialSource) ? initialSource : source}
                error={ false }
                form={ form }
                debugError={ hasDebug && debugError }
                step={ parserStep }
                stepNumber={ index }
                onRemove={() => {
                  const isOnlyStep = fields.length === 1;
                  if (isOnlyStep) {
                    form.setValue(`steps.${index}.name`, '');
                  } else {
                    remove(index);
                  }
                }}
              />
              { index !== fields.length - 1 && <StepConnector onClickAdd={() => insert(index + 1, { name: '', __type: 'block', id: uuid() })} /> }
            </div>
          );
        })}
        { (!limitOne || fields.length === 0) && (
          <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" color="secondary" onClick={() => append({ name: '', __type: 'block', id: uuid() })}>+</Button>
          </div>
        ) }
      </div>
    </div>
  );
}

StepBuilder.propTypes = {
  limitOne: PropTypes.bool,
  disabled: PropTypes.bool,
  source: PropTypes.string.isRequired,
  initialSource: PropTypes.string,
  syntax: PropTypes.oneOf(['gherkin', 'default']),
};

StepBuilder.defaultProps = {
  limitOne: false,
  disabled: false,
  initialSource: null,
  syntax: 'default',
};
