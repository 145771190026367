import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import Link from '../../components/Link';
import TableCell from '@material-ui/core/TableCell';
import TrashIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router';
import useCurrentProject from '../../hooks/useCurrentProject';
import useIntegrations from '../../hooks/useIntegrations';
import useSites from '../../hooks/useSites';
import { deleteIntegrationsByProject } from '../../slices/integrations';
import useQuery from '../../hooks/useQuery';

export function IntegrationIndex({ integrations, siteId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useCurrentProject();
  const sites = useSites();
  const site = sites.find(s => s.id === siteId);

  let createIntegrationUrl = '/integrations/create';

  if (siteId) {
    createIntegrationUrl = `${createIntegrationUrl}?siteId=${siteId}`;
  }

  return (
    <div style={{ padding: 32, display: 'flex', justifyContent: 'center' }}>
      <div style={{ maxWidth: 700, width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 32 }}>
          <div>
            <Typography variant="h4">{!!site && site.name} Integrations</Typography>
            <Typography variant="body2">
              An Integration tells your Extension how and when to read data and inject content on a website
            </Typography>
          </div>
        </div>
        <Card>
          { !!integrations.all.length && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  { !site && <TableCell>Site</TableCell> }
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integrations.all.map(integration => {
                  const integrationSite = sites.find(site => integration.siteId === site.id);
                  return (
                    <TableRow>
                      <TableCell>
                        <Link to={`/integrations/${integration.id}?projectId=${project.id}`}>
                          {integration.name}
                        </Link>
                      </TableCell>
                      { !site && (
                        <TableCell>
                          { integrationSite ? integrationSite.name : '--' }
                        </TableCell>
                      ) }
                      <TableCell style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                          onClick={() => dispatch(deleteIntegrationsByProject({ integrationId: integration.id, projectId: project.id }))}
                        >
                          <TrashIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) }
          { !integrations.all.length && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 16 }}>
              <Typography variant="h5">Nothing here yet</Typography>
              <Typography variant="body2">
                <Link to={createIntegrationUrl} as={Button}>Add an Integration</Link> to get started.
              </Typography>
            </div>
          ) }
        </Card>
        { !!integrations.all.length && (
          <div style={{ marginTop: 16 }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => history.push(createIntegrationUrl)}
            >
              Add Integration
            </Button>
          </div>
        ) }
      </div>
    </div>
  )
}

export default function IntegrationIndexPage() {
  const isLoading = useSelector(state => state.projects.pending);
  const query = useQuery();
  const siteId = query.get('siteId');
  const integrations = useIntegrations({ siteId });

  if (isLoading) {
    return (
      <div>
        Loading...
      </div>
    );
  }

  return <IntegrationIndex integrations={integrations} siteId={siteId} />
}
