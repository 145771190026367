import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useLocation } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Hidden from '@material-ui/core/Hidden';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import BuildIcon from '@material-ui/icons/Build';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HelpIcon from '@material-ui/icons/Help';
import WebIcon from '@material-ui/icons/Web';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import useCurrentProject from '../hooks/useCurrentProject';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  appIdLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 16,
  },
  appId: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  }
}));

function NavListItem({ to, ...props }) {
  const CustomLink = React.useMemo(
    () => forwardRef((linkProps, ref) => (
      <Link ref={ref} to={to} {...linkProps} />
    )),
    [to],
  );

  return (
    <ListItem
      button
      component={CustomLink}
      {...props}
    />
  );
}

function DrawerMenu({ project }) {
  const location = useLocation();
  const classes = useStyles();

  return (
    <List>
      <ListItem>
        <ListItemText>
          <Typography
            variant="caption"
            className={ classes.appIdLabel }
          >
            App ID:
          </Typography>
          <Typography variant="caption" className={ classes.appId }>
            { project.id }
          </Typography>
        </ListItemText>
      </ListItem>
      <NavListItem to={`/settings?projectId=${project.id}`} selected={location.pathname.startsWith('/settings')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText>Setup</ListItemText>
      </NavListItem>
      <Divider />
      <NavListItem to={`/sites?projectId=${project.id}`} selected={location.pathname.startsWith('/sites')}>
        <ListItemIcon>
          <WebIcon />
        </ListItemIcon>
        <ListItemText>Sites</ListItemText>
      </NavListItem>
      <NavListItem to={`/integrations?projectId=${project.id}`} selected={location.pathname.startsWith('/integrations')}>
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText>Integrations</ListItemText>
      </NavListItem>
      <NavListItem to={`/monitoring?projectId=${project.id}`} selected={location.pathname.startsWith('/monitoring')}>
        <ListItemIcon>
          <NotificationsIcon />
        </ListItemIcon>
        <ListItemText>Monitoring</ListItemText>
      </NavListItem>
      <Divider />
      <NavListItem to={`/billing?projectId=${project.id}`} selected={location.pathname.startsWith('/billing')}>
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText>Billing</ListItemText>
      </NavListItem>
      <NavListItem to="/help">
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText>Help</ListItemText>
      </NavListItem>
    </List>
  );
}

export default function AppDrawer({ open, onClose }) {
  const classes = useStyles();
  const currentProject = useCurrentProject();

  if (!currentProject) { return null; }

  return (
    <Fragment>
      <Hidden smDown implementation="css">
        <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
          <Toolbar />
          <DrawerMenu project={currentProject} />
        </Drawer>
      </Hidden>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          open={ open }
          onClose={ onClose }
          ModalProps={{ keepMounted: true }}
          anchor="left"
        >
          <DrawerMenu project={currentProject} />
        </Drawer>
      </Hidden>
    </Fragment>
  );
}

AppDrawer.propTypes = { open: PropTypes.bool.isRequired, onClose: PropTypes.func.isRequired };
