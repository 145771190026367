import CenteredContent from '../../components/CenteredContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import AddIcon from '@material-ui/icons/Add';
import Card from '@material-ui/core/Card';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import useSites from '../../hooks/useSites';
import useCurrentProject from '../../hooks/useCurrentProject';
import Link from '../../components/Link';

export default function SitesPage() {
  const sites = useSites();
  const history = useHistory();
  const project = useCurrentProject();
  const { id: projectId } = project;

  return (
    <CenteredContent>
      <Typography variant="h4">Sites</Typography>
      <Typography variant="body2">Manage sites your extension runs on</Typography>

      <Card style={{ marginTop: 32, marginBottom: 16 }}>
        { !!sites.length && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Site Name</TableCell>
                <TableCell>Domains</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { sites.map(site =>(
                <TableRow>
                  <TableCell>
                    <Link to={`/integrations?projectId=${projectId}&siteId=${site.id}`}>
                      { site.name }
                    </Link>
                  </TableCell>
                  <TableCell>{ (site.domains instanceof Array) ? site.domains.join(', ') : site.domains }</TableCell>
                </TableRow>
              )) }
            </TableBody>
          </Table>
        ) }
        { !sites.length && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5">Nothing Here</Typography>
            <Typography variant="p">Create a site to get started</Typography>
          </div>
        ) }
      </Card>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={() => history.push(`/sites/create?projectId=${projectId}`) }
      >
        Add Site
      </Button>
    </CenteredContent>
  );
}
