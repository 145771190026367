import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {firestore, auth } from '../firebase';

export const getAnchorsByProject = createAsyncThunk(
  'anchors/fetchByProject',
  async ({ projectId }) =>  {
    const { docs } = await firestore.collection('Anchors')
      .where('projectId', '==', projectId)
      .where('ownerId', '==', auth.currentUser.uid)
      .get();

    return docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
);

export const deleteAnchorsByProject = createAsyncThunk(
  'anchors/delete',
  async ({anchorId }) =>  {
    return firestore.collection('Anchors')
      .doc(anchorId)
      .delete();
  },
);

export const saveAnchor = createAsyncThunk(
  'anchors/save',
  async (anchor) => {

    if (anchor.id) {
      await firestore.collection('Anchors').doc(anchor.id).set({
        ownerId: auth.currentUser.uid,
        ...anchor,
      }, { merge: true });

      return anchor;
    }
    const res = await firestore.collection('Anchors').add({ ownerId: auth.currentUser.uid, ...anchor });

    return { id: res.id, ...anchor, ownerId: auth.currentUser.uid };
  }
);

export default createSlice({
  name: 'anchors',
  initialState: {
    byProject: {},
  },

  extraReducers: {
    [getAnchorsByProject.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.byProject[action.payload[0].projectId] = { cachedAt: Date.now(), all: [] };
      }
      action.payload.forEach((anchor) => {
        state.byProject[anchor.projectId].all.push(anchor);
      });
    },

    [getAnchorsByProject.rejected]: (state, action) => {
      console.error('error getting anchors', action);
    },

    [saveAnchor.fulfilled]: (state, action) => {
      const anchor = action.payload;
      const projectId = anchor.projectId;
      const anchors = state.byProject[projectId] = state.byProject[projectId] || { cachedAt: null, all: [] };
      let isNew = true;

      anchors.all = anchors.all.map(existingAnchor => {
        if (anchor.id === existingAnchor.id) {
          isNew = false;
          return anchor;
        }

        return existingAnchor;
      });

      if (isNew) {
        anchors.all.push(anchor);
      }
    },
    [saveAnchor.rejected]: (state, action) => {
      console.error('rejected anchor', action);
    },

    [deleteAnchorsByProject.fulfilled]: (state, action) => {
      console.log('Anything',action);
      state.byProject[action.meta.arg.projectId].all.forEach((anchor, index) => {
        if (anchor.id === action.meta.arg.anchorId) {
          state.byProject[action.meta.arg.projectId].all.splice(index, 1);
        }
      });
      console.log('Success', action);
    },
    [deleteAnchorsByProject.rejected]:(state, action) => {
      console.error('Delete', action);
    }
  }
})
