import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getAllSites} from '../slices/sites';
import useCurrentProject from './useCurrentProject';

export default function useSites() {
  const cachedAt = useSelector(state => state.sites.cachedAt);
  const sites = useSelector(state => state.sites.all);
  const currentProject = useCurrentProject();
  const dispatch = useDispatch();
  const currentProjectId = currentProject && currentProject.id;

  useEffect(() => {
    if (cachedAt || !currentProjectId) { return; }

    dispatch(getAllSites({ projectId: currentProjectId }));
  }, [cachedAt, currentProjectId, dispatch]);

  return sites;
}
