import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import { getAllProjects } from '../slices/projects';

export default function useProjects() {
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.all);
  const projectsCachedAt = useSelector(state => state.projects.cachedAt);

  useEffect(() => {
    if (projectsCachedAt) { return; }

    dispatch(getAllProjects());
  }, [projectsCachedAt, dispatch]);

  return projects;
}
