import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { searchBlocks } from '../slices/blocks';

export default function useBlock(blockId, source) {
  const block = useSelector(state => state.blocks.byId[blockId]);
  const needsInitial = useSelector(state => !state.blocks.isLoading && !state.blocks.cachedAt);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!needsInitial) { return; }

    dispatch(searchBlocks({ term: '', source }));
  }, [needsInitial, dispatch, source]);


  return block;
}
