import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {firestore, auth } from '../firebase';

export const getParsersByProject = createAsyncThunk(
  'parsers/fetchByProject',
  async ({ projectId }) =>  {
    const { docs } = await firestore.collection('Parsers')
      .where('projectId', '==', projectId)
      .where('ownerId', '==', auth.currentUser.uid)
      .get();

    return docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
);

export const deleteParsersByProject = createAsyncThunk(
  'parsers/delete',
  async ({parserId }) =>  {
    return firestore.collection('Parsers')
      .doc(parserId)
      .delete();
  },
);

export const createParser = createAsyncThunk(
  'parsers/create',
  async (parser) => {
    if (parser.id) {
      await firestore.collection('Parsers').doc(parser.id).set({
        ownerId: auth.currentUser.uid,
        ...parser,
      }, { merge: true });

      return parser;
    }
    const res = await firestore.collection('Parsers').add({ ownerId: auth.currentUser.uid, ...parser });

    return { id: res.id, ...parser, ownerId: auth.currentUser.uid };
  }
)

export default createSlice({
  name: 'parsers',
  initialState: {
    byProject: {},
  },

  extraReducers: {
    [getParsersByProject.fulfilled]: (state, action) => {
      if (action.payload.length) {
        state.byProject[action.payload[0].projectId] = { cachedAt: Date.now(), all: [] };
      }
      action.payload.forEach((parser) => {
        state.byProject[parser.projectId].all.push(parser);
      });
    },

    [getParsersByProject.rejected]: (state, action) => {
      console.error('failed to get parsers', action);
    },

    [createParser.fulfilled]: (state, action) => {
      const parser = action.payload;
      const projectId = parser.projectId;
      const parsers = state.byProject[projectId] = state.byProject[projectId] || { cachedAt: null, all: [] };
      let isNew = true;

      parsers.all = parsers.all.map(existingParser => {
        if (parser.id === existingParser.id) {
          isNew = false;
          return parser;
        }

        return existingParser;
      });

      if (isNew) {
        parsers.all.push(parser);
      }
    },
    [createParser.rejected]: (state, action) => {
      console.error('rejected parser', action);
    },
    [deleteParsersByProject.fulfilled]: (state, action) => {
      console.log('Anything',action);
      state.byProject[action.meta.arg.projectId].all.forEach((parser, index) => {
        if (parser.id === action.meta.arg.parserId) {
          state.byProject[action.meta.arg.projectId].all.splice(index, 1);
        }
      });
      console.log('Success', action);
    },
    [deleteParsersByProject.rejected]:(state, action) => {
      console.error('Delete', action);
    }
  }
})
