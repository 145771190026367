import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {auth} from '../firebase';
import firebase from 'firebase';

let firebaseui;
let authUi;

if (global.window) {
  firebaseui = require('firebaseui');
  authUi = new firebaseui.auth.AuthUI(auth);

  const local = firebase.auth.Auth.Persistence.LOCAL;
  auth.setPersistence(local)
}

export const login = createAsyncThunk(
  'user/login',
  () => {
    return new Promise(resolve => {
      setTimeout(() => {
        authUi.start('#firebase-auth-container', {
          callbacks: {
            signInSuccessWithAuthResult(authResult, redirectUrl) {
              resolve(authResult.user.toJSON());
            }
          },
          signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          ],
        });
      });
    });
  }
);

const slice = createSlice({
  name: 'user',
  initialState: {
    isLoggingIn: false,
    currentUser: auth && auth.currentUser,
  },
  reducers: {
    authStateChanged: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.isLoggingIn = true;
    },
    [login.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
      state.isLoggingIn = true;
    },
    [login.rejected]: (state, action) => {
      console.error('login rejected', action);
      state.isLoggingIn = false;
      state.didFail = true;
    }
  }
});

export default slice;
